body {
  .io-d-flex {
    display: flex;
  }
  .io-d-none {
    display: none;
  }
  .io-d-show {
    display: unset;
  }
  .io-p {
    padding: 8px;
  }
  .io-p-2 {
    padding: 16px;
  }
  .io-p-3 {
    padding: 24px;
  }
  .io-px-3 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .io-pl-3 {
    padding-left: 24px;
  }
  .io-pr-3 {
    padding-right: 24px;
  }
  .io-pt-3 {
    padding-top: 24px;
  }
  .io-mt-0 {
    margin-top: 0 !important;
  }
  .io-ml-a {
    margin-left: auto;
  }
  .io-pb {
    padding-bottom: 8px;
  }
  .io-pb-2 {
    padding-bottom: 16px;
  }
  .io-pb-3 {
    padding-bottom: 24px;
  }
  .io-mb {
    margin-bottom: 8px;
  }
  .io-mb-2 {
    margin-bottom: 16px;
  }
  .io-mb-3 {
    margin-bottom: 24px;
  }
  .io-gap {
    gap: 8px;
  }
  .io-gap-2 {
    gap: 16px;
  }
  .io-gap-3 {
    gap: 24px;
  }
}
.io-row,
.io-row-m,
.io-row-l,
.io-row-xl,
.io-row-xxl {
  flex-direction: column;
  display: flex;
  gap: 16px;
  &.io-inline {
    display: inline-flex;
  }
  &.io-no-gap {
    gap: 0px;
  }
  & > *,
  .io-flex {
    flex: 1;
  }
  .io-no-flex {
    flex: none;
  }
  .io-flex-2 {
    flex: 2;
  }
  .io-flex-3 {
    flex: 3;
  }
  &.io-align-items-center {
    align-items: center;
  }
  .mat-form-field-infix {
    width: 100%;
  }
}
.io-width-100 {
  width: 100% !important;
}
.io-row {
  flex-direction: row;
}

@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .io-row-m {
    flex-direction: row;
  }
  .io-d-m-none {
    display: none;
  }
  .io-d-m-show {
    display: flex !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .io-row-l {
    flex-direction: row;
  }
  .io-d-l-none {
    display: none !important;
  }
  .io-d-l-show {
    display: flex !important;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .io-row-xl {
    flex-direction: row;
  }
  .io-d-xl-none {
    display: none;
  }
  .io-d-xl-show {
    display: flex !important;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .io-row-xxl {
    flex-direction: row;
  }
  .io-d-xxl-none {
    display: none;
  }
  .io-d-xxl-show {
    display: flex !important;
  }
}

.placeholder {
  display: block;
  position: relative;
  width: 100%;
  background-color: white;
  &.ratio-4x3 {
    aspect-ratio: 4/3;
  }
  &.ratio-1x1 {
    aspect-ratio: 1;
  }
  &.ratio-16x9 {
    aspect-ratio: 16/9;
  }
  &.ratio-4x2 {
    aspect-ratio: 5/2;
  }
}
.io-card {
  box-shadow: 0px 2px 3px 0px rgba($color: #091e42, $alpha: 0.15);
  border-radius: 4px;
  background-color: white;
}
