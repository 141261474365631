@use "@angular/material" as mat;
@import "./assets/grid";

$xray-palette: (
  50: #e3f6f9,
  100: #b9e9f0,
  200: #90dbe6,
  300: #6ecbda,
  400: #5fc1d1,
  500: #5bb6c8,
  600: #55a6b6,
  700: #4e919c,
  800: #477d85,
  900: #3c5a5b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: black,
    // ... continues to 900
  ),
);

$my-primary: mat.define-palette($xray-palette, 500);
$my-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);
@include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.inv-list {
  // box-sizing: border-box;
  @media (min-width: 1025px) {
    margin: 24px;
    box-shadow: 0 0 14px 4px rgba($color: #091e42, $alpha: 0.15);
    border-radius: 4px;
  }
  padding-top: 0 !important;
  overflow: hidden;
}

.inv-list:empty {
  display: none;
}

.inv-list small {
  font-size: 9pt;
  opacity: 0.6;
}
.inv-list .mat-list-item.deleted {
  border-left: 2px solid red;
  color: rgb(175, 175, 175);
}
.inv-list .mat-list-item.deleted h3 {
}

.infoblock {
  margin: 16px 0;
  padding: 24px;
  &.warning {
    background-color: rgba($color: #ff0000, $alpha: 0.03);
    color: rgba($color: #ff0000, $alpha: 0.75);
    border: 1px solid rgba($color: #ff0000, $alpha: 0.33);
  }
  &.notice {
    background-color: rgba($color: #5fc1d1, $alpha: 0.03);
    // color: rgba($color: #5fc1d1, $alpha: 0.95);
    border: 1px solid rgba($color: #5fc1d1, $alpha: 0.33);
  }
  &.info {
    background-color: rgba($color: #393c46, $alpha: 0.03);
    color: rgba($color: #393c46, $alpha: 1);
    border: 1px solid rgba($color: #393c46, $alpha: 0.33);
  }
}
mat-form-field {
  width: 100%;
  min-width: 50px;
}

.dialog-close-btn {
  position: relative;
  float: right;
  top: -10px;
  right: -10px;
}
a.link {
  color: map-get($xray-palette, 700);
}

mat-sidenav,
.mat-drawer-inner-container {
  overflow: hidden !important;
}

.sidebar-outlet {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

code {
  background-color: #e7e6eb;
  color: #32363f;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 80%;
}
.p-4 {
  padding: 1.5rem !important;
}

.text-center {
  text-align: center !important;
}
